import * as React from 'react'
import { ReactComponent as UserIcon } from '../../../Assests/Icons/UserIcon.svg'
import { ReactComponent as CopyPasteIcon } from '../../../Assests/Icons/CopyPaste.svg'
import { ReactComponent as Editicon } from '../../../Assests/Icons/Edit icon.svg'
import { Button, Icon, IconButton, Tooltip } from '@mui/material'
import { ReactComponent as Logout } from '../../../Assests/Icons/Logout.svg'
import buttonStyles from '../../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../../Styles/component/Typography.module.css'
import { useNavigate } from 'react-router-dom'
import styles from './Sidebar.module.css'
import LogoutDialog from '../../User/customLogout'
import { SnackbarContext } from '../../../Components/Snackbar/SnackbarProvider'
import ProfileDialog from './ProfileDialog/ProfileDialog'
import { routerArr } from '../../Routes'
import { useEditProfile } from '../../../Services/profileService/editProfileService'
import imagePlaceHolder from '../../../Assests/Icons/Icons svg/Imagethumbnail.png'
import { useSelector } from 'react-redux'

export default function Sidebar() {
    const currentPath = window.location.pathname
    const navigate = useNavigate()
    const [open, setOpen] = React.useState<boolean>(false)
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const [editOpen, setEditOpen] = React.useState<boolean>(false)
    const user = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user,
    )
    const {
        handleChange,
        handleRemove,
        profileDialogPic,
        profilePic,
        getUsetData,
        setProfileDialogPic,
    } = useEditProfile()
    const openDialog = async () => {
        setEditOpen(true)
        setProfileDialogPic(null)
        await getUsetData()
    }
    const closeDialog = () => {
        setEditOpen(false)
    }
    const navigationSidebar = (path: string) => {
        navigate(path)
    }
    const handleLogout = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const onloadimage = () => {
        URL.revokeObjectURL(profilePic)
    }

    const onErrorImage = (e: any) => {
        e.target.src = imagePlaceHolder
    }
    return (
        <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <div className={styles.rootDiv}>
                <div
                    className={
                        profilePic !== null
                            ? styles.rootDivPrimary
                            : `${styles.rootDivPrimary} ${styles.defPro}`
                    }
                >
                    {profilePic === null ? (
                        <UserIcon />
                    ) : (
                        <img
                            onError={(e) => onErrorImage(e)}
                            onLoad={() => onloadimage()}
                            draggable={false}
                            src={profilePic}
                            alt="img1"
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '6px',
                            }}
                        />
                    )}
                    <Tooltip title="Edit Profile">
                        <IconButton
                            className={styles.editIcon}
                            onClick={() => openDialog()}
                        >
                            <Editicon className={styles.penIcon} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={styles.rootDivPrimary1}>
                    <div className={styles.rootDivPrimary2}>
                        <span
                            className={`${typoStyles.typoRobotoBoldprimary718}`}
                        >
                            {user?.firstName + ' ' + user?.lastName}
                        </span>
                    </div>
                    <div className={styles.rootDivPrimary3}>
                        <span
                            className={`${typoStyles.typoRobotoRegoular14} ${styles.emailSpan}`}
                        >
                            {user?.email}
                        </span>
                        <Tooltip title="Copy Email Id">
                            <Button
                                className={styles.copybtn}
                                size="small"
                                onClick={() => {
                                    if (user?.email) {
                                        snackbarSetting(
                                            true,
                                            'Email ID copied successfully',
                                            'success',
                                        )
                                        navigator.clipboard.writeText(
                                            user?.email,
                                        )
                                    }
                                }}
                            >
                                <CopyPasteIcon />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div style={{ height: '100%' }}>
                {routerArr?.map(
                    (item) =>
                        item.label !== 'Coding' &&
                        item.permission?.some((ro) => user?.userRole === ro) &&
                        item.id !== 3 && (
                            <Button
                                key={item.id}
                                className={
                                    currentPath !== item.path
                                        ? styles.ListButton
                                        : styles.ListButtonSelected
                                }
                                onClick={() => navigationSidebar(item.path)}
                            >
                                <div className={styles.sameLine}>
                                    <Icon
                                        className={
                                            currentPath === item.path
                                                ? `${buttonStyles.activeIcon} ${styles.iconTag}`
                                                : `${buttonStyles.svgIcon} ${styles.iconTag}`
                                        }
                                    >
                                        {item.icon}{' '}
                                    </Icon>
                                    {item.label}
                                </div>
                            </Button>
                        ),
                )}
                {open && <LogoutDialog open={open} handleClose={handleClose} />}
            </div>
            <div className={styles.rootDivPrimary4}>
                <Button
                    className={styles.logoutBtn}
                    onClick={() => handleLogout()}
                >
                    <div className={styles.sameLine}>
                        <Logout />
                        {'Logout'}
                    </div>
                </Button>
            </div>
            <ProfileDialog
                open={editOpen}
                onClose={closeDialog}
                handleChange={handleChange}
                handleRemove={handleRemove}
                profilePic={profileDialogPic}
            />
        </div>
    )
}
